<template>
  <div>
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <h2 class="content-header-title float-start mb-0">Department Master</h2>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb float-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Department Master</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section id="dashboard-ecommerce">
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-body">
                <div class="row mb-1">
                  <div class="d-flex col-12">
                    <div class="float-start col-6">
                      <div class="input-group input-group-merge w-50">
                        <span class="input-group-text" id="basic-addon-search2"
                          ><vue-feather type="search"></vue-feather></span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search..."
                          aria-label="Search..."
                          aria-describedby="basic-addon-search2"
                          v-model="search"
                          @keyup="searchData"
                        />
                      </div>
                    </div>
                    <!-- data-bs-toggle="modal"
                    data-bs-target="#modals-slide-in" -->
                    <div class="float-end col-6">
                      <button
                        type="button"
                        class="btn btn-gradient-primary float-end"
                        @click="addDep"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                <section id="ajax-datatable">
                  <div class="row">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-datatable">
                          <table
                            class="dt-column-search table table-responsive"
                          >
                            <thead>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-show="list.length > 0" v-for="(li, index) in list" :key="li.id">
                                <td>{{ index + paginationData.from }}</td>
                                <td>{{ li.dep_name }}</td>
                                <td>{{ li.created_at }}</td>
                                <td>{{ li.updated_at }}</td>
                                <td class="d-flex">
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-primary rounded"
                                        @click="editDep(li.id)"
                                      >
                                        <div class="avatar-content">
                                          <vue-feather type="edit" size="15"></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                  <div
                                    class="d-flex justify-content-start align-items-center mb-75"
                                  >
                                    <span class="me-75">
                                      <div
                                        class="avatar bg-light-danger rounded"
                                        @click="deleteDep(li.id)"
                                      >
                                        <div class="avatar-content">
                                          <vue-feather type="trash-2" size="15"></vue-feather>
                                        </div>
                                      </div>
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr v-show="list.length === 0">
                                <td colspan="5" class="text-center">No Data Found</td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </tfoot>
                          </table>
                          <div class="row d-flex">
                            <div class="col-12 d-flex">
                              <div class="col-6 showing_entry">
                                Showing {{ paginationData.from }} to
                                {{ paginationData.to }} of
                                {{ paginationData.total }} entries
                              </div>
                              <div class="col-6">
                                <Pagination
                                  :data="paginationData"
                                  @pagination-change-page="getResults"
                                  show-disabled="true"
                                  limit="3"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  {{modalShow}}
  <teleport to="#modal">
    <div class="modal modal-slide-in new-user-modal fade" id="modals-slide-in" :class="isShow" :style="{display: isDisplay}">
      <div class="modal-dialog">
        <form
          class="add-new-user modal-content pt-0"
          method="post"
          @submit.prevent="submitForm"
        >
          <input type="hidden" name="id" :v-modal="id" />
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="[isShow='', isDisplay='none']"
          >
            ×
          </button>
          <div class="modal-header mb-1">
            <h4 class="modal-title" id="exampleModalLabel">
              {{ modalShow }} Department
            </h4>
          </div>
          <div class="modal-body flex-grow-1">
            <div class="mb-1">
              <label class="form-label" for="basic-addon-name"
                >Department Name</label
              >
              <input
                type="text"
                class="form-control"
                name="dep_name"
                v-model="dep_name"
                placeholder="Department Name"
                @keyup="
                  dep_name.length > 0
                    ? [(depError = 'none'), (this.disabled = false)]
                    : (depError = 'block')
                "
              />
              <h1>{{tag}}</h1>
              <div
                class="invalid-feedback fw-bold fst-italic"
                :style="{ display: depError }"
              >
                Please enter Department name.
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-primary me-1 data-submit"
              :disabled="disabled"
            >
              Submit 
            </button>
            <button
              type="reset"
              class="btn btn-outline-secondary"
              :disabled="disabled"
              data-bs-dismiss="modal"
              @click="[isShow='', isDisplay='none']"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </teleport>
</template>

<script>
import axios from "axios";
import LaravelVuePagination from "laravel-vue-pagination";

export default {
  name: "Department",
  components: {
    Pagination: LaravelVuePagination,
  },
  data() {
    return {
      id: null,
      dep_name: "",
      response: "",
      disabled: false,
      header: [],
      list: [],
      depDetail: {},
      modalName: "Add",
      paginationData: {},
      search: null,
      depError: "none",
      isShow: "",
      isDisplay: "none",
      token: localStorage.getItem('auth_token')
    };
  },
  async mounted() {
    this.header = [
      "Sr. no.",
      "Department Name",
      "Created Date",
      "Updated Date",
      "Action",
    ];
    this.getResults();
  },
  methods: {
    async addDep() {
      (this.id = null), (this.dep_name = ""), (this.response = "");
      this.isShow = "show"
      this.isDisplay = "block"
    },
    async getResults(page = 1) {
      await axios
        .get(process.env.VUE_APP_API_URL+"/department_master?page=" + page, 
        {
          headers: {
            "Content-Type": "application/json",
            'Accept': "application/json",
            "Authorization": `Bearer ${this.token}`
          }
        })
        .then((response) => {
          this.paginationData = response.data.data;
        });
      this.list = this.paginationData.data;
    },
    async submitForm() {
      this.disabled = true;
      if (this.dep_name.length === 0) {
        this.depError = "block";
      } else {
        if (this.id) {
          await axios
            .put(process.env.VUE_APP_API_URL+"/department_master/" + this.id,
              {
                dep_name: this.dep_name,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${this.token}`
                },
              }
            )
            .then((response) => {
              if (response.data.status) {
                this.$toast.success(response.data.message);
                this.disabled = false;
                this.isShow = ""
                this.isDisplay = "none"
                this.getResults();
              } else {
                this.$toast.error(response.data.error?.[0]);
                this.disabled = false;
              }
            })
            .catch((error) => {
              this.$toast.error(error);
              this.disabled = false;
            });
        } else {
          await axios
            .post(process.env.VUE_APP_API_URL+"/department_master",
              {
                dep_name: this.dep_name,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${this.token}`
                },
              }
            )
            .then((response) => {
              if (response.data.status) {
                this.$toast.success(response.data.message);
                this.disabled = false;
                this.isShow = ""
                this.isDisplay = "none"
                this.getResults();
              } else {
                this.$toast.error(response.data.error?.[0]);
                this.disabled = false;
              }
            })
            .catch((error) => {
              this.$toast.error(error);
              this.disabled = false;
            });
        }
      }
    },
    deleteDep(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ms-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(process.env.VUE_APP_API_URL+"/department_master/" + id,
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${this.token}`
                },
              }
            )
            .then(response => {
              this.getResults();
                if (response.data.status) {
                  this.$toast.success(response.data.message);
                } else {
                  this.$toast.error(response.data.error?.[0]);
                }
            })
            .catch((error) => {
              this.$toast.error(error);
            });
        }
      });
    },
    async editDep(id) {
      this.depError= "none"
      this.disabled= false
      const output = await axios.get(process.env.VUE_APP_API_URL+"/department_master" + (id ? "/" + id : ""),
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${this.token}`
                },
              });
      this.id = output.data.data?.id;
      this.dep_name = output.data.data?.dep_name;
      this.modalName = "Edit";
      this.isShow = "show"
      this.isDisplay = "block"
    },
    async searchData() {
      await axios
        .post(process.env.VUE_APP_API_URL+"/department_master/search",
          {
            search: this.search,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": `Bearer ${this.token}`
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            this.list = response.data.data.data;
            this.isShow = ""
            this.isDisplay = "none"
          } else {
            this.$toast.error(response.data.error?.[0]);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
  },
};
</script>
<style></style>
